import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import CircleIcon from "@mui/icons-material/Circle";
import FestivalIcon from "@mui/icons-material/Festival";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import StoreIcon from "@mui/icons-material/Store";
import {
  AdvancedMarker,
  APIProvider,
  Map as GoogleMap,
  Pin,
} from "@vis.gl/react-google-maps";
import dayjs from 'dayjs';

import CustomAxios from "../utils/CustomAxios";
import { IssuerData } from "../type";

const REGION = process.env.REACT_APP_REGION as string;
const GOOGLE_MAPS_ID = process.env.REACT_APP_GOOGLE_MAPS_ID as string;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

const today = dayjs();

export const Map = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [issuerData, setIssuerData] = useState<IssuerData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultPosition, setDefaultPosition] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 0, lng: 0 });

  useEffect(() => {
    const savedIssuers = localStorage.getItem("issuers");
    if (!location?.state?.noReload || !savedIssuers) {
      setIsLoading(true);
      CustomAxios.get("/issuers", { params: { region: REGION } })
        .then((res) => {
          setIssuerData(res.data.issuers);
          calcCenter(res.data.issuers);
          localStorage.setItem("issuers", JSON.stringify(res.data.issuers));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const issuerData = JSON.parse(savedIssuers) as IssuerData[];
      setIssuerData(issuerData);
      calcCenter(issuerData);
    }
  }, [location]);

  const calcCenter = (data: IssuerData[]) => {
    // 地図の中央値を計算
    let latSum = 0;
    let lngSum = 0;
    let latNum = 0;
    let lngNum = 0;
    data.forEach((item: IssuerData) => {
      if (item.position) {
        latSum += item.position.lat;
        lngSum += item.position.lng;
        latNum++;
        lngNum++;
      }
    });
    if (latNum && lngNum) {
      setDefaultPosition({
        lat: latSum / latNum,
        lng: lngSum / lngNum,
      });
    }
  };

  const MapIcon = ({
    type,
    name,
    eventEndDate,
  }: {
    type: string;
    name: string;
    eventEndDate?: string;
  }) => {
    let icon;
    let color;

    if (type === "event") {
      icon = <FestivalIcon fontSize="medium" />;
      color = "#0f9d58";

      // イベントが終了している場合はグレーアウト
      if (eventEndDate) {
        const endDate = dayjs(eventEndDate);
        if (endDate.format('YYYYMMDD') < today.format('YYYYMMDD')) {
          color = "#757575";
        }
      }
    } else if (type === "restaurant") {
      color = "#f9a825";
      icon = <RestaurantIcon fontSize="medium" />;
    } else if (type === "store") {
      color = "#0288d1";
      icon = <StoreIcon fontSize="medium" />;
    } else if (type === "stamp") {
      color = "#ff5252";
      icon = <BrandingWatermarkIcon fontSize="medium" />;
    } else {
      icon = <CircleIcon fontSize="medium" />;
      color = "#3949ab";
    }

    return (
      <Pin
        background={color}
        borderColor={"#424242"}
        glyphColor={"white"}
        scale={2}
      >
        <>
          {icon}
          <div
            style={{
              fontSize: "1rem",
              position: "absolute",
              bottom: "-40px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "200px",
              fontWeight: "bold",
              color: color,
              WebkitTextStrokeColor: "#424242",
              WebkitTextStrokeWidth: "0.1px",
            }}
          >
            <span
              style={{
                backgroundColor: "rgba(255,255,255,0.99)",
                paddingLeft: "1.5px",
                paddingRight: "1.5px",
              }}
            >
              {name}
            </span>
          </div>
        </>
      </Pin>
    );
  };

  return (
    <>
      {isLoading && (
        <CircularProgress
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            m: "auto",
          }}
        />
      )}
      {!isLoading && defaultPosition.lat && (
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
          <GoogleMap
            mapId={GOOGLE_MAPS_ID}
            style={{ width: "100vw", height: "100vh" }}
            defaultCenter={defaultPosition}
            defaultZoom={12}
            gestureHandling={"greedy"}
            disableDefaultUI={true}
          >
            {issuerData.map(
              (item, index) =>
                item.position && (
                  <AdvancedMarker
                    key={index}
                    position={item.position}
                    onClick={() => navigate(`/map/${item.uuid}`)}
                    title={item.name}
                  >
                    <MapIcon
                      type={item.type}
                      name={item.name}
                      eventEndDate={item.eventEndDate}
                    />
                  </AdvancedMarker>
                )
            )}
          </GoogleMap>
        </APIProvider>
      )}
    </>
  );
};
